import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import "./admin_login.css";

import log from "./../assets/HG_logo.png";

const AdminLogin = () => {
  // handles the login credentials

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(
        'https://homchang.in//login.php',
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.success) {
        setMessage('Login successful!');
        localStorage.setItem("isAuthenticated", "true"); // Store authentication status
        navigate('/adminHome'); // Redirect to admin dashboard
      } else {
        setMessage(response.data.message || 'Invalid email or password.');
      }
    } catch (error) {
      console.error('There was an error logging in!', error);
      setMessage('Error occurred. Try again later.');
    }
  };
  
  

  // it is used to restrict from mobile phone

  useEffect(() => {
    if (window.innerWidth < 768) {
      window.location.href = "/not-available"; // Redirect to "Not Available" page for mobile devices
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login-container">
        <img src={log} alt="Company Logo" className="login-logo" />
        <h2 className="login-title">Admin Login</h2>

        <form className="login-form" onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>

        {message && <p> {message}</p>}
      </div>
    </div>
  );
};

export default AdminLogin;
